import React from "react";
import PageLayout from "../components/layout/PageLayout";
import Seo from "../components/layout/SEO";
import PrivacyContainer from "../components/PrivacyContainer";

import policyAnim from "../assets/json/policy.json";

const Privacy = () => {
  return (
    <PageLayout anim={policyAnim}>
      <Seo title="Privacy Policy - Simplicitic Innovations Sdn Bhd" />
      <section>
        <PrivacyContainer />
      </section>
    </PageLayout>
  );
};

export default Privacy;
